/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
	margin: 0
	padding: 0
	border: 0
	font-size: 100%
	font: inherit

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
	display: block

html
	overflow-y: scroll

body
	line-height: 1

ol,
ul
	list-style: none

blockquote,
q
	quotes: none

blockquote:before,
blockquote:after,
q:before,
q:after
	content: ''
	content: none

table
	border-collapse: collapse
	border-spacing: 0

input:-webkit-autofill
	box-shadow: inset 0 0 0 1000px rgba(0,0,0,0)

select::-ms-expand
	display: none
input[type="number"]
	-moz-appearance: textfield
input[type="number"]::-webkit-outer-spin-button
input[type="number"]::-webkit-inner-spin-button
	-webkit-appearance: none
	margin: 0

input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner
	border: 0px

select
	-moz-appearance: button
	-webkit-appearance: button

select:-moz-focusring
	color: transparent
	text-shadow: 0 0 0 #828c9a
